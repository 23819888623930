import Konva from 'konva';
import VueKonva from 'vue-konva';
import { debounce } from 'lodash';
import { Nullable } from '~/types/common';
import { components } from '~~/api-types';

export type LogoDto = components['schemas']['CreateContentSocialLogoDto'];
export type AddImagePayload = {
  fileUrl: string;
  id: string | number;
  logo?: LogoDto;
  preview?: boolean;
};

export type Box = {
  x: number;
  y: number;
  height: number;
  width: number;
  rad?: number;
  id?: number;
};

const MIN_QUADRANT_SIZE = 40;

const QUADRANT_DEFAULT_CONFIG = {
  fill: 'rgba(4, 41, 87, 1)',
  strokeScaleEnabled: false,
  rotation: 0,
  scaleX: 1,
  scaleY: 1,
};

export const useQuadrants = ({
  emit,
  quadrants: initialValue,
  stage,
  layer,
  transformer,
  imageLayer,
}) => {
  const components: Record<string, any> = {};
  VueKonva.install({
    component(key: string, component: any) {
      components[key] = component;
    },
  });
  const {
    vStage: VStage,
    vLayer: VLayer,
    vRect: VRect,
    vGroup: VGroup,
    vTransformer: VTransformer,
    vImage: VImage,
  } = components;

  const quadrants = ref<Konva.RectConfig[]>([]);
  const scale = ref(1);
  const currentQuadrant = ref('');
  const isSelected = ref(false);

  const distance = (x1: number, y1: number, x2: number, y2: number) => {
    return Math.hypot(x2 - x1, y2 - y1);
  };

  const findClosestRectangle = (box: Box): Konva.RectConfig => {
    let [closestRectangle] = quadrants.value;
    let closestDistance = distance(
      box.x + box.width / 2,
      box.y + box.height / 2,
      closestRectangle.x + closestRectangle.width / 2,
      closestRectangle.y + closestRectangle.height / 2,
    );

    for (let i = 1; i < quadrants.value.length; i++) {
      const rect = quadrants.value[i];
      const dist = distance(
        box.x + box.width / 2,
        box.y + box.height / 2,
        rect.x + rect.width / 2,
        rect.y + rect.height / 2,
      );
      if (dist < closestDistance) {
        closestRectangle = rect;
        closestDistance = dist;
      }
    }

    return closestRectangle;
  };

  const moveToQuadrant = (rect, closest) => {
    const newX = Math.max(
      closest.x,
      Math.min(closest.x + closest.width - rect.width, rect.x),
    );
    const newY = Math.max(
      closest.y,
      Math.min(closest.y + closest.height - rect.height, rect.y),
    );

    return { x: newX, y: newY, width: rect.width, height: rect.height };
  };

  const imageRef = ref<Nullable<Konva.Image>>(null);

  const addQuadrant = (coords: Box) => {
    quadrants.value.push({
      ...QUADRANT_DEFAULT_CONFIG,
      x: Number(coords.x),
      y: Number(coords.y),
      height: Number(coords.height),
      width: Number(coords.width),
      name: String(coords.id),
    });
  };

  const updateTransformer = () => {
    const transformerNode = transformer.value?.getNode();

    if (!transformerNode) {
      return;
    }

    if (imageRef.value && isSelected.value) {
      transformerNode.nodes([imageRef.value]);
    } else {
      transformerNode.nodes([]);
    }
  };

  const hideTransformer = () => {
    isSelected.value = false;
    updateTransformer();
  };

  const handleStageMouseDown = (e: Konva.KonvaEventObject<MouseEvent>) => {
    if (e.target === e.target.getStage()) {
      hideTransformer();
      return;
    }

    const clickedOnTransformer =
      e.target.getParent()?.className === 'Transformer';
    if (clickedOnTransformer) {
      return;
    }

    const name = e.target.name();
    isSelected.value = name === 'logo';
    updateTransformer();
  };

  const renderImage = () => {
    const stageEl = stage.value.getStage();
    const layerEl = layer.value.getNode();
    const transformerEl = transformer.value?.getNode();

    if (stageEl.width() === 0 || stageEl.height() === 0) {
      return;
    }

    transformerEl?.hide();
    layerEl.hide();

    const scale = stageEl.scaleX();

    const dataUrl = stageEl.toDataURL({
      mimeType: 'image/jpeg',
      pixelRatio: 1 / scale,
    });

    transformerEl?.show();
    layerEl.show();

    isSelected.value = true;
    updateTransformer();
    return dataUrl;
  };

  const loadQuadrants = (quadrants: Box[] = []) => {
    for (const quadrant of quadrants) {
      addQuadrant(quadrant);
    }
    const stageEl = stage.value.getStage();
    const group = stageEl.findOne('.quadrants-group');

    nextTick(() => {
      group.cache();
    });

    renderImage();
  };

  const emitRenderedImage = debounce(async () => {
    const value = await renderImage();
    emit('update:imageData', value);
  }, 100);

  const isPointInsideContainers = (point: { x: number; y: number }) => {
    const EPSILON = 0.0001;
    const { x, y } = point;
    return quadrants.value.some((quadrant) => {
      return (
        x >= quadrant.x - EPSILON &&
        x <= quadrant.x + quadrant.width + EPSILON &&
        y >= quadrant.y - EPSILON &&
        y <= quadrant.y + quadrant.height + EPSILON
      );
    });
  };

  const isLogoInsideQuadrants = (logo: Box, range: number): boolean => {
    if (quadrants.value.length === 0) {
      return false;
    }

    for (let { x } = logo; x <= logo.x + logo.width; x += range) {
      if (
        !isPointInsideContainers({ x, y: logo.y }) ||
        !isPointInsideContainers({ x, y: logo.y + logo.height / 2 }) ||
        !isPointInsideContainers({ x, y: logo.y + logo.height })
      ) {
        return false;
      }
    }

    for (let { y } = logo; y <= logo.y + logo.height; y += range) {
      if (
        !isPointInsideContainers({ x: logo.x, y }) ||
        !isPointInsideContainers({ x: logo.x + logo.width / 2, y }) ||
        !isPointInsideContainers({ x: logo.x + logo.width, y })
      ) {
        return false;
      }
    }

    const steps = Math.floor(Math.max(logo.width, logo.height) / range);
    for (let step = 0; step <= steps; step++) {
      const currentStep = step * range;
      const diagonalX1 = logo.x + currentStep;
      const diagonalY1 = logo.y + currentStep;
      const diagonalX2 = logo.x + logo.width - currentStep;
      const diagonalY2 = logo.y + logo.height - currentStep;

      if (
        diagonalX1 <= logo.x + logo.width &&
        diagonalY1 <= logo.y + logo.height &&
        !isPointInsideContainers({ x: diagonalX1, y: diagonalY1 })
      ) {
        return false;
      }
      if (
        diagonalX2 >= logo.x &&
        diagonalY2 >= logo.y &&
        !isPointInsideContainers({ x: diagonalX2, y: diagonalY2 })
      ) {
        return false;
      }
    }

    if (
      !isPointInsideContainers({ x: logo.x, y: logo.y }) ||
      !isPointInsideContainers({ x: logo.x + logo.width, y: logo.y }) ||
      !isPointInsideContainers({ x: logo.x, y: logo.y + logo.height }) ||
      !isPointInsideContainers({
        x: logo.x + logo.width,
        y: logo.y + logo.height,
      })
    ) {
      return false;
    }

    return true;
  };

  const emitLogoPosition = (image: Konva.Image): void => {
    emit('update:logo', {
      x: Math.round(image.attrs.x * 100) / 100,
      y: Math.round(image.attrs.y * 100) / 100,
    });
  };

  const emitLogoDimensions = (width: number, height: number): void => {
    emit('update:logo', {
      width: Math.round(width),
      height: Math.round(height),
    });
  };

  const emitImage = (image: Konva.Image): void => {
    emitLogoPosition(image);
    emitLogoDimensions(image.attrs.width, image.attrs.height);

    emit('update:logo', {
      accountLogoId: image.attrs.id,
    });
  };

  const getFittedImageDimensions = (
    image: Box,
    closestRectangle: Konva.RectConfig,
  ) => {
    if (!closestRectangle.width || !closestRectangle.height) {
      return { width: image.width, height: image.height };
    }

    const imageAspectRatio = image.width / image.height;
    const rectangleAspectRatio =
      closestRectangle.width / closestRectangle.height;

    let fittedWidth: number;
    let fittedHeight: number;

    if (imageAspectRatio > rectangleAspectRatio) {
      fittedWidth = closestRectangle.width;
      fittedHeight = closestRectangle.width / imageAspectRatio;
    } else {
      fittedWidth = closestRectangle.height * imageAspectRatio;
      fittedHeight = closestRectangle.height;
    }

    return { width: fittedWidth, height: fittedHeight };
  };

  const onImageDragOutsideQuadrant = (
    scaledPosition: Box,
    image: Konva.Image,
  ) => {
    const closestRectangle = findClosestRectangle(scaledPosition);

    if (
      !closestRectangle.height ||
      !closestRectangle.width ||
      !closestRectangle.x ||
      !closestRectangle.y
    ) {
      return;
    }

    const newImagePosition = moveToQuadrant(scaledPosition, closestRectangle);
    const isImageSmallerThenRectangle =
      newImagePosition.width <= closestRectangle.width &&
      newImagePosition.height <= closestRectangle.height;

    image.position(newImagePosition);

    if (closestRectangle.name === currentQuadrant.value) {
      currentQuadrant.value = closestRectangle.name || '';
      return;
    }

    if (isImageSmallerThenRectangle) {
      return image;
    }

    const { width, height } = getFittedImageDimensions(
      newImagePosition,
      closestRectangle,
    );

    image.height(height);
    image.width(width);
    image.scaleX(1);
    image.scaleY(1);
    image.position({
      x: closestRectangle.x,
      y: closestRectangle.y,
    });
  };

  const onImageDragMove = (
    event: Konva.KonvaEventObject<MouseEvent>,
    image: Konva.Image,
  ): void => {
    const position = event.target.getClientRect();
    const scaledPosition = {
      x: position.x / scale.value,
      y: position.y / scale.value,
      width: position.width / scale.value,
      height: position.height / scale.value,
    };

    const range = Math.floor(
      Math.min(scaledPosition.width, scaledPosition.height) / 10,
    );

    if (isLogoInsideQuadrants(scaledPosition, range)) {
      emitLogoPosition(image);
      emitRenderedImage();
      return;
    }

    onImageDragOutsideQuadrant(scaledPosition, image);
    emitImage(image);
    emitRenderedImage();
  };

  const addImage = ({ fileUrl, id, logo, preview }: AddImagePayload) => {
    const newLogo: Nullable<LogoDto> = id === logo?.accountLogoId ? logo : null;
    const stageEl = stage.value.getStage();
    // Removing old logo
    const oldLayer = stageEl.findOne('.image-layer');
    if (oldLayer) {
      oldLayer.destroy();
      hideTransformer();
    }

    Konva.Image.fromURL(fileUrl, (image) => {
      const imgLayer = new Konva.Layer({
        name: 'image-layer',
      });

      const width = image.width() / scale.value;
      const height = image.height() / scale.value;

      const imagePosition = {
        x: newLogo?.x || Number(quadrants.value[0].x),
        y: newLogo?.y || Number(quadrants.value[0].y),
      };

      const { width: newWidth, height: newHeight } = getFittedImageDimensions(
        { width, height, ...imagePosition },
        quadrants.value[0],
      );

      image.position(imagePosition);
      image.draggable(!preview);
      image.height(newLogo?.height || newHeight);
      image.width(newLogo?.width || newWidth);
      image.name('logo');
      image.attrs.id = id;

      image.on('dragmove', (event) => onImageDragMove(event, image));

      imageRef.value = image;
      imgLayer.add(image);
      stageEl.add(imgLayer);

      emitRenderedImage();
      emitImage(image);
    });
  };

  const getTransformedImage = (oldValue: Box, newValue: Box) => {
    const scaledNewValue = {
      x: newValue.x / scale.value,
      y: newValue.y / scale.value,
      width: newValue.width / scale.value,
      height: newValue.height / scale.value,
    };

    if (
      scaledNewValue.width < MIN_QUADRANT_SIZE / scale.value ||
      scaledNewValue.height < MIN_QUADRANT_SIZE / scale.value
    ) {
      return oldValue;
    }

    const range = Math.floor(
      Math.min(scaledNewValue.width, scaledNewValue.height) / 10,
    );

    if (isLogoInsideQuadrants(scaledNewValue, range)) {
      return newValue;
    }

    return oldValue;
  };

  const boundFunc = (oldValue: Box, newValue: Box) => {
    const value = getTransformedImage(oldValue, newValue);

    emitLogoDimensions(value.width / scale.value, value.height / scale.value);
    emitRenderedImage();

    return value;
  };

  onMounted(() => {
    const stageEl = stage.value.getStage();
    scale.value = stageEl.scaleX();
  });

  watch(
    () => scale.value,
    () => {
      loadQuadrants(initialValue);
    },
  );

  const rerenderStage = (imgId: string) => {
    const imageEl = document.querySelector(`#${imgId}`);

    Konva.Image.fromURL(imageEl?.src || '', (imageNode: Konva.Image) => {
      const node = imageLayer.value?.getNode();
      node.children = [];
      node.add(imageNode);
    });

    let width = imageEl?.clientWidth || 0;
    let height = imageEl?.clientHeight || 0;

    const naturalWidth = imageEl?.naturalWidth || 0;
    const naturalHeight = imageEl?.naturalHeight || 0;
    const aspectRatio = naturalWidth / naturalHeight;

    if (aspectRatio > 1) {
      height = Math.round(width / aspectRatio);
      scale.value = width / naturalWidth;
    } else {
      width = Math.round(height * aspectRatio);
      scale.value = height / naturalHeight;
    }

    const stageEl = stage.value.getStage();

    stageEl.height(height);
    stageEl.width(width);

    stageEl.scale({
      x: scale.value,
      y: scale.value,
    });

    if (imageRef.value) {
      emitRenderedImage();
    }
  };

  return {
    VRect,
    VStage,
    VLayer,
    VGroup,
    VTransformer,
    VImage,
    loadQuadrants,
    quadrants,
    addImage,
    renderImage,
    handleStageMouseDown,
    boundFunc,
    rerenderStage,
  };
};
