<template>
  <div
    class="absolute overflow-hidden w-full h-full flex items-center justify-center"
  >
    <VStage
      ref="stage"
      :config="stageConfig"
      @mousedown="handleStageMouseDown"
      @tap="handleStageMouseDown"
    >
      <VLayer ref="imageLayer" />
      <VLayer ref="layer">
        <VGroup
          v-if="!preview"
          ref="group"
          name="quadrants-group"
          :config="{
            opacity: 0.5,
            ...stageConfig,
          }"
        >
          <VRect v-for="item in quadrants" :key="item.name" :config="item" />
        </VGroup>

        <VTransformer
          v-if="!preview"
          ref="transformer"
          :rotate="false"
          :rotateEnabled="false"
          :flipEnabled="false"
          :keepRatio="true"
          :boundBoxFunc="boundFunc"
          :enabledAnchors="[
            'top-left',
            'top-right',
            'bottom-left',
            'bottom-right',
          ]"
        />
      </VLayer>
    </VStage>
  </div>
</template>

<script setup lang="ts">
import { Box, LogoDto, useQuadrants } from '~/composables/use-quadrants';
import { useQuadrantsStore } from '~/store/quadrants-store';
import { watch } from 'vue';
import { Nullable } from '~/types/common';
import Konva from 'konva';

const props = defineProps<{
  quadrants: Box[];
  logo?: LogoDto;
  imageData: string;
  preview?: boolean;
}>();

const emit = defineEmits<{
  'update:imageData': [value: string];
  'update:logo': [value: LogoDto];
}>();

const stageConfig = ref({
  width: 100,
  height: 100,
});

const stage = ref<Nullable<Konva.Stage>>(null);
const layer = ref<Nullable<Konva.Layer>>(null);
const imageLayer = ref<Nullable<Konva.Layer>>(null);
const group = ref<Nullable<Konva.Group>>(null);
const transformer = ref<Nullable<Konva.Transformer>>(null);

const {
  VStage,
  VLayer,
  VRect,
  VGroup,
  VTransformer,
  quadrants,
  boundFunc,
  addImage,
  handleStageMouseDown,
  renderImage,
  rerenderStage,
} = useQuadrants({
  emit,
  stage,
  layer,
  transformer,
  imageLayer,
  quadrants: props.quadrants,
});

const store = useQuadrantsStore();

const selectedLogo = computed(() => store.getLogoById(store.selectedLogoId));

watch(
  () => selectedLogo.value,
  (value) => {
    if (!value) {
      return;
    }

    const imagePayload = {
      fileUrl: value,
      id: store.selectedLogoId,
      logo: props.logo,
      preview: props.preview,
    };

    addImage(imagePayload);
  },
);

useAsyncData(async () => {
  await store.getLogos();
});

onMounted(() => {
  if (props.logo) {
    store.selectLogo(props.logo.accountLogoId);
    emit('update:logo', props.logo);
    rerenderStage('preview-image');
  }
});

onUnmounted(() => {
  store.selectLogo('');
});

defineExpose({
  rerenderStage,
});
</script>
